import HeaderPos from "components/layouts/HeaderPos";
import React, { useState, useEffect } from "react";
import CustomDateRange from "components/fragments/CustomDateRangeSaleSummary";
import SaleSumCard from "components/layouts/SaleSumCard";
import SumDoughnutCard from "components/layouts/SumDoughnutCard";
import SumBestSellerCard from "components/layouts/SumBestSellerCard";
import SumLineGraph from "components/layouts/SumLineGraph";
import ModalPinShowValue from "components/layouts/ModalPinShowValue";
import { useRecoilValue } from 'recoil'
import { authState } from 'state/auth';
import { summaryState, useFetchBranchSummary } from 'state/summary';
import moment from "moment";

export default function SalesSummaryPage() {
  const [sumGraphList, setSumGraphList] = useState([
    {
      id: "jsdjfl",
      labels: ["ทานที่ร้าน", "ซื้อกลับบ้าน", "เดลิเวอรี่"],
      headline: "ยอดขายแบ่งตามประเภทออเดอร์",
      unitTxt: "บาท",
      data: []
    },
    {
      id: "jsdjfxxl",
      labels: ["ทานที่ร้าน", "ซื้อกลับบ้าน", "เดลิเวอรี่"],
      headline: "จำนวนบิลแบ่งตามประเภทออเดอร์",
      unitTxt: "บิล",
      data: []
    },
    {
      id: "jsdffjfl",
      labels: ["ลูกค้าสมาชิก", "ลูกค้าทั่วไป"],
      headline: "จำนวนลูกค้า",
      unitTxt: "ออเดอร์",
      data: []
    },
  ]);

  const [bestSellerList, setBestSellerList] = useState(
    // Array.from({ length: 10 }, (v, i) => ({
    //   id: i,
    //   name: "ต้มยำกุ้ง",
    //   price: 120,
    //   sales: 200,
    // }))
    []
  );

  const [paymentDataList, setPaymentDataList] = useState([
    {
      icon: (
        <img
          className="object-contain aspect-[27.09/26.14] w-[min(2.20vw,22.51px)] h-fit"
          src="/img/icons/icon-dollar-green.svg"
          alt=""
        />
      ),
      value: parseInt(Math.random() * 5000),
      label: "ทั้งหมด",
    },
    {
      icon: (
        <img
          className="object-contain aspect-[22.51/22.63] w-[min(2.20vw,22.51px)] h-fit"
          src="/img/icons/icon-dollar-green.svg"
          alt=""
        />
      ),
      value: parseInt(Math.random() * 5000),
      label: "เงินสด",
    },
    {
      icon: (
        <img
          className="object-contain aspect-[27.09/26.14] w-[min(2.20vw,22.51px)] h-fit"
          src="/img/icons/icon-transfer-green.svg"
          alt=""
        />
      ),
      value: parseInt(Math.random() * 5000),
      label: "เงินโอน",
    },
    {
      icon: (
        <img
          className="object-contain aspect-[22.51/16.97] w-[min(2.20vw,22.51px)] h-fit"
          src="/img/icons/icon-credit-green.svg"
          alt=""
        />
      ),
      value: parseInt(Math.random() * 5000),
      label: "บัตรเครดิต",
    },
    {
      icon: (
        <img
          className="object-contain aspect-[24.2/20.9] w-[min(2.36vw,24.2px)] h-fit"
          src="/img/icons/icon-scan-green.svg"
          alt=""
        />
      ),
      value: parseInt(Math.random() * 5000),
      label: "QR",
    },
    {
      icon: (
        <img
          className="object-contain aspect-[19.18/25.92] w-[min(1.87vw,19.18px)] h-fit"
          src="/img/icons/icon-delivery-green.svg"
          alt=""
        />
      ),
      value: parseInt(Math.random() * 5000),
      label: "Delivery",
    },
    {
      icon: (
        <img
          className="object-contain aspect-[37.25/24.9] w-[min(3.64vw,37.25px)] h-fit"
          src="/img/icons/icon-other-payment.png"
          alt=""
        />
      ),
      value: parseInt(Math.random() * 5000),
      label: "ช่องทางอื่นๆ",
    },
  ]);
  const [isShowValue, setIsShowValue] = useState(false);
  const [modalPinShowValueOpen, setModalPinShowValueOpen] = useState(false);
  const { user } = useRecoilValue(authState);
  const { summary } = useRecoilValue(summaryState);
  // console.log("🚀 ~ SalesSummaryPage ~ summary:", summary)
  const fetchBranchSummary = useFetchBranchSummary();
  // const currentDate = moment();
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
  });

  const handleDateRangeSelect = async (startDate, endDate) => {
    // console.log("🚀 ~ handleDateRangeSelect ~ startDate:", startDate.format("YYYY-MM-DD"))
    // console.log("🚀 ~ handleDateRangeSelect ~ endDate:", endDate.format("YYYY-MM-DD"))
    setSelectedDate({
        startDate: startDate,
        endDate: endDate
    })
    await fetchBranchSummary({
      branchId: user.branchId,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      // paymentChannel: 'Cash'
    })
  }

  useEffect(() => {
    if(user.id && user.branchId) {
      fetchBranchSummary({
        branchId: user.branchId,
        startDate: selectedDate.startDate.format("YYYY-MM-DD"),
        endDate: selectedDate.endDate.format("YYYY-MM-DD"),
        // paymentChannel: 'Cash'
      })
    }
  }, [user])

  useEffect(() => {
    if(summary) {
      // total
      if(summary.totalPayments && summary.totalPayments.length > 0) {
        let newPaymentDataList = [...paymentDataList];
        newPaymentDataList.forEach(paymentDetail => paymentDetail.value = summary.totalPayments.find(tp => tp.label == paymentDetail.label).total);
        setPaymentDataList(newPaymentDataList);
      }

      // orders
      if(summary.count_order_type && summary.count_order_type.length > 0) {
        var newCount = [...sumGraphList];
        newCount[0].data = summary.count_order_type.filter(e => e.name != "ทั้งหมด");
        setSumGraphList(newCount)
      }

      // bills
      if(summary.count_bills_ordertype && summary.count_bills_ordertype.length > 0) {
        var newCount = [...sumGraphList];
        newCount[1].data = summary.count_bills_ordertype.filter(e => e.name != "ทั้งหมด");
        setSumGraphList(newCount)
      }

      // members
      if(summary.count_member && summary.count_member.length > 0) {
        var newCount = [...sumGraphList];
        newCount[2].data = summary.count_member.filter(e => e.name != "ทั้งหมด");
        setSumGraphList(newCount)
      }

      // top product
      if(summary.top_product_orders && summary.top_product_orders.length > 0) {
        const productParsed =  summary.top_product_orders.map((product,i) => ({
          id: product.id,
          name: product.name,
          price: product.price,
          sales: product.total,
          img_url: `https://dev-api.allder.co/${product.img_url}`,
        }))
        setBestSellerList(productParsed)
      } else {
        setBestSellerList([])
      }
    }
  }, [summary])

  return (
    <>
      <div className="bg-[#FFF] min-h-[100vh]">
        {/* header */}
        <HeaderPos isNotify={true} isSearch={false} />
        {/* body */}
        <div className="flex px-[min(1.32vw,13.55px)] pt-[min(0.68vw,7px)] pb-[min(3.22vw,33px)] min-h-[100vh]">
          <div className="bg-[#F8F8F8] w-full rounded-[min(1.46vw,15px)] px-[min(1.56vw,16px)] pt-[min(2.78vw,28.5px)] pb-[min(6.46vw,66.13px)]">
            <div className="max-w-[min(87.70vw,898px)]">
              <div className="flex justify-between">
                <div className="flex gap-[min(2.64vw,27px)]">
                  <div className="kanit text-18 font-[700]">
                    ยอดขายแบ่งตามประเภทช่องทางรับเงิน
                  </div>
                  <div className="flex items-center gap-[min(0.34vw,3.49px)] text-14 text-[#A0A0A0]">
                    <img
                      className="object-contain aspect-square w-[min(1.07vw,11px)] h-fit"
                      src="/img/icons/icon-arrow-circle-grey.svg"
                      alt=""
                    />
                    {/* <div>อัพเดท 17 ต.ค. 2566</div> */}
                    <div>อัพเดท {new Date().toLocaleDateString("th-TH", { year:"numeric", month:"short", day:"numeric" })}</div>
                  </div>
                </div>

                <div className="w-[min(22.95vw,235px)]">
                  <CustomDateRange
                    inputprops={{
                      type: "text",
                      className:
                        "rounded-[min(0.45vw,4.59px)] h-[min(2.92vw,29.85px)] px-[min(1.27vw,13px)] py-[min(1.03vw,10.5px)] text-12 leading-[4] placeholder:h-full text-[rgba(76,78,100,0.87)] border-[1px] border-[var(--color-grey-4)] custom-input-date-range cursor-pointer",
                      placeholder: "-",
                      name: "date-range",
                    }}
                    handleDateRangeSelect={handleDateRangeSelect}
                    selectedDate={selectedDate}
                  />
                </div>
              </div>
              <div>
                <div className="flex justify-end mt-[min(1.77vw,18.15px)]">
                  {/* show value */}
                  <div
                    onClick={() => {
                      if (!isShowValue) {
                        return setModalPinShowValueOpen(true);
                      }
                      setIsShowValue(false);
                    }}
                    className="flex items-center gap-[min(0.39vw,4px)] cursor-pointer btn-transparent-active w-fit px-[min(0.39vw,4px)]"
                  >
                    <img
                      className="object-contain aspect-square w-[min(1.37vw,14px)] h-fit"
                      src={
                        isShowValue
                          ? "/img/icons/icon-eye-slash-green.svg"
                          : "/img/icons/icon-eye-green.svg"
                      }
                      alt=""
                    />
                    <div className="text-[var(--color-green)] text-14">
                      {isShowValue ? "ปิดยอด" : "เปิดยอด"}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mt-[min(1.37vw,14px)]">
                  {paymentDataList.map((v, i) => {
                    return (
                      <SaleSumCard
                        key={i}
                        info={{ ...v }}
                        isActive={i === 0}
                        isShowValue={isShowValue}
                      />
                    );
                  })}
                </div>
              </div>

              {/* graph */}
              <div className="flex justify-between gap-[min(1.56vw,16px)] mt-[min(1.37vw,14px)]">
                {sumGraphList.map((v, i) => {
                  if(v.data.length > 0) {
                    return <SumDoughnutCard key={i} info={{ ...v }} />;
                  }
                })}
              </div>

              <div className="flex justify-between gap-[min(1.56vw,16px)] mt-[min(1.37vw,14px)]">
                <div className="bg-[#FFF] aspect-[593/266] rounded-[min(0.88vw,9px)] w-[min(57.91vw,593px)] h-fit shadow-1-8 p-[min(2.08vw,21.34px)] flex flex-col justify-between">
                  <div className="text-14 font-[700]">จำนวนบิลขายรายวัน</div>
                  <div className="w-full h-[min(12.89vw,132px)]">
                    <SumLineGraph lineData={summary?.count_bills_date}/>
                  </div>
                </div>
                <div className="bg-[#FFF] aspect-[289/266] rounded-[min(0.88vw,9px)] w-[min(28.22vw,289px)] h-fit shadow-1-8 p-[min(1.34vw,13.7px)] relative">
                  <div className="flex justify-between items-center">
                    <div className="text-14 font-[600]">Top 10 เมนูขายดี</div>
                    <div className="btn-transparent-active cursor-pointer">
                      <img
                        className="object-contain aspect-square w-[min(1.60vw,16.39px)] h-fit"
                        src="/img/icons/icon-more-vertical-grey.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* product list */}
                  <div className="flex flex-col gap-[min(0.71vw,7.29px)] h-[min(19.53vw,200px)] overflow-y-auto">
                    {bestSellerList.map((v, i) => {
                      return (
                        <SumBestSellerCard
                          key={v.id}
                          info={{ ...v }}
                          number={i + 1}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPinShowValue
        modalOpenObj={{
          modalOpen: modalPinShowValueOpen,
          setModalOpen: setModalPinShowValueOpen,
        }}
        setShowValue={setIsShowValue}
      />
    </>
  );
}
