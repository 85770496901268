import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { 
    getAllNotifications,
    readNotifications
} from '../services/notification';

const baseAPI = "https://dev-api.allder.co/";

// State
export const notificationState = atom({
    key: 'notificationState',
    default: {
        notifications: [],
    },
});

// Action
export const useFetchAllNotification = () => {
    const setState = useSetRecoilState(notificationState);

    return async (posId, operationDate) => {
        try {
            const fetch = await getAllNotifications(posId, operationDate);
            // console.log("🚀 ~ return ~ fetch:", fetch)
            setState(prev => ({ ...prev, notifications: fetch.notifications }));
            return fetch.notifications
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useReadNotification = () => {
    const setState = useSetRecoilState(notificationState);

    return async (notificationList, operationDate) => {
        // console.log("🚀 ~ return ~ notificationList:", notificationList)
        try {
            const update = await readNotifications(notificationList, operationDate);
            // console.log("🚀 ~ return ~ update:", update)
            // setState(prev => ({ ...prev, notifications: update.notifications }));
            // return update.notifications
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};