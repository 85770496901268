import React, { useEffect, useState } from "react";

import Calculate from "components/layouts/payment/Calculator";
import Formmoneypay from "components/layouts/payment/Inputmoneypay";
import { nanoid } from 'nanoid'

// const baseAPI = "http://localhost:3000/";
const baseAPI = "https://dev-api.allder.co/";

const deliveryLogoList = [
    { "id": 1, "name": "GrabFood", "logo": "/img/delivery/GrabFood.png" },
    { "id": 2, "name": "LINE MAN", "logo": "/img/delivery/lineman.png" },
    { "id": 3, "name": "foodpanda", "logo": "/img/delivery/foodpanda.png" },
    { "id": 4, "name": "Get", "logo": "/img/delivery/get.png" },
    { "id": 5, "name": "NOW", "logo": "/img/delivery/NOW.png" },
    { "id": 6, "name": "LALAMOVE", "logo": "/img/delivery/lalamove.png" },
    { "id": 7, "name": "SKOOTAR", "logo": "/img/delivery/skootar.png" },
];

export default function  PayDelivery({
    moneypayObj,
    eObj,
    PaymentDetailObj,
    btnObj,
    DeliveryDetailObj,
    Payment,
    deliveryObj,
    providerObj,
    paymentDeliveryList,
    ...props
    }) {
    // console.log("🚀 ~ Payment:", Payment)
    // console.log("🚀 ~ DeliveryDetailObj:", DeliveryDetailObj)
    
    const [step, setStep] = useState(1);
    const [delivery, setDelivery] = useState(null);
    // console.log("🚀 ~ delivery:", delivery)

    const handleCheckPaymentChannel = () => {
        if(DeliveryDetailObj.DeliveryDetail && DeliveryDetailObj.DeliveryDetail.paymentChannel == 2){
            providerObj.setProvider(delivery)
            deliveryObj.setDeliverySelect(delivery)
            const item = {
                "id":  nanoid() ,
                "channel": Payment.PaymentSelect,
                // "amount": PaymentDetailObj.PaymentDetail.paymentNet,
                "amount": Math.abs(PaymentDetailObj.PaymentDetail.paymentRemaining),
                "provider": delivery,
                "remark": DeliveryDetailObj.DeliveryDetail.remark,
                "status": 1
            }
            Payment.setPaymentObj( (prev) => [ ...prev,item ])
            // Payment.setPaymentSelect({"paymentChannelID":1,"paymentChannelName":"เงินสด"})
            Payment.setPaymentSelect(prev =>({ ...prev, paymentChannelName: "ชำระผ่านผู้บริการ" }));
            moneypayObj.setMoneypay('0.00')
            setStep(2)
        } else {
            providerObj.setProvider(delivery)
            deliveryObj.setDeliverySelect(delivery)
            // Payment.setPaymentObj( (prev) => [ ...prev,item ])
            // Payment.setPaymentSelect({"paymentChannelID":1,"paymentChannelName":"เงินสด"})
            Payment.setPaymentSelect(prev =>({ ...prev, paymentChannelID: "1", paymentChannelName: "ชำระปกติ" }));
            // setStep(2)
        }

    }

    // useEffect(() => {
    //   return () => {
    //     setStep(1)
    //   }
    // }, [])

    return (
        <div className="d-flex flex-column h-100">
            {
                step == 2 ? (
                    
                    <AddAmount 
                        moneypayObj={moneypayObj}
                        eObj={eObj}
                        PaymentDetailObj={PaymentDetailObj}
                        btnObj={btnObj}
                        DeliveryDetailObj={DeliveryDetailObj}
                    />
                ) : (
                    <DeliverySelect 
                        d={{
                            delivery,
                            setDelivery
                        }}
                        stepObj={{
                            step,
                            setStep
                        }}
                        btnObj={btnObj}
                        handleClick={() => handleCheckPaymentChannel() }
                        paymentDeliveryList={paymentDeliveryList}
                    />
                )
            }
            

        </div>
    )

}

const DeliverySelect = ({d,stepObj,handleClick, paymentDeliveryList}) => {

    // const [deliveryList, setDeliveryList] = useState([
    //     { "id": 1, "name": "GrabFood", "logo": "/img/delivery/GrabFood.png" },
    //     { "id": 2, "name": "LINE MAN", "logo": "/img/delivery/lineman.png" },
    //     { "id": 3, "name": "foodpanda", "logo": "/img/delivery/foodpanda.png" },
    //     { "id": 4, "name": "Get", "logo": "/img/delivery/get.png" },
    //     { "id": 5, "name": "NOW", "logo": "/img/delivery/NOW.png" },
    //     { "id": 6, "name": "LALAMOVE", "logo": "/img/delivery/lalamove.png" },
    //     { "id": 7, "name": "SKOOTAR", "logo": "/img/delivery/skootar.png" },
    // ]);

    const [deliveryList, setDeliveryList] = useState();

    useEffect(() => {
        if(paymentDeliveryList && paymentDeliveryList.length > 0) {
            for (const pd of paymentDeliveryList) {
                const newDeliveryList = [];
                for (const pd of paymentDeliveryList) {
                    const deliveryLogo = deliveryLogoList.filter(dlg => dlg.name == pd.name)[0];
                    newDeliveryList.push({
                        ...pd,
                        // logo: deliveryLogo.logo
                        // logo: baseAPI + pd.delivery_image
                        logo: pd.delivery_image
                    });
                }
                setDeliveryList(newDeliveryList);
            }
        }
    }, [paymentDeliveryList])
    

    return (
        <div className="d-flex h-100 flex-column">
            <div className="bx-paylist p-3 overflow-auto mb-auto" style={{ background: '#FFF',height:'calc(100vh - 450px)' }}>
                <div className="row">
                {
                    deliveryList && deliveryList.map((item, index) => 

                    <div className="col-4" key={index}>
                        <div className="d-flex align-items-center justify-content-center flex-column mb-3" style={{cursor:'pointer'}} 
                        onClick={() => d.setDelivery(item) }
                        >
                            <img src={item.logo} style={ d.delivery && item.id == d.delivery.id ?
                                {width: '100px',height: '100px',objectFit:'cover',borderRadius:'8px',border:'5px solid #2DC76D',boxShadow:' 0px 2px 2px 0px rgba(0, 0, 0, 0.25)'} :
                                {width: '100px',height: '100px',objectFit:'cover',borderRadius:'8px'}
                            } />
                            <div className="text-[#061126] fs-16 fw-500 mt-3 ms-3">{item.delivery_name ? item.delivery_name : item.name}</div>
                        </div>
                    </div>
                    )
                }
                </div>

            </div>
            
            <div className="d-flex align-items-center justify-content-end pt-2">
                <button className=" btn btn-st-q1 px-4"
                onClick={() => handleClick() }
                disabled={ !d.delivery }
                >ยืนยัน
                </button>
            </div>
        </div>
    )
}

const AddAmount = ({moneypayObj,eObj,PaymentDetailObj,btnObj,DeliveryDetailObj}) => {
    return (
        <div>
            <div className="d-flex align-items-center text-[#061126] mb-1">
                <div className="me-auto">
                <span className="fs-18 fw-500">Order NO.</span>
                <span className="fs-14 fw-500 ms-2">{PaymentDetailObj.PaymentDetail.orderNo}</span>
                </div>
                <div className="fs-18 fw-bold text-[#2DC76D]">ชำระเงินปกติ</div>
            </div>
            <div className="fs-14 fw-500 mb-3">กรุณาระบุยอดเงินรับชำระ</div>
            <div className="py-2">
                <Formmoneypay 
                moneypay={moneypayObj.moneypay}
                setMoneypay={moneypayObj.setMoneypay}
                />
            </div>

            <Calculate 
                setMoneypay={moneypayObj.setMoneypay}
                moneypay={moneypayObj.moneypay}
                PaymentDetail={PaymentDetailObj.PaymentDetail}
            />

            <div className="d-flex align-items-center  pt-2">
                <button className=" btn btn-st-q1 me-auto px-4"
                onClick={eObj.handleAddPayment}
                disabled={btnObj.BtnenterDisabled || Number(moneypayObj.moneypay) == 0}
                >Enter
                </button>
                <button className="btn btn-st-q1"
                onClick={eObj.handlePayment}
                disabled={btnObj.BtnpaymentDisabled}
                >ชำระเงิน</button>
            </div>
        </div>
    )
}