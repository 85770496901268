import axiosInstance from './axios';

const endPoint = "/notification";
// const baseAPI = "http://localhost:3000/";
const baseAPI = "https://dev-api.allder.co/";

export const getAllNotifications = async (posId, operationDate) => {
    try {

        const queryParams = {
            pos_id: posId ? Number(posId) : null,
            operation_date: operationDate ?? null
        }
        // console.log("🚀 ~ getAllNotifications ~ queryParams:", queryParams)

        const response = await axiosInstance.get('/notification', { params: queryParams });
        // console.log("🚀 ~ getAllNotifications ~ response:", response)

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            notifications: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const readNotifications = async (notificationList, operationDate) => {
    try {

        const payload = {
            notificationList: notificationList ?? null,
            operation_date: operationDate ?? null
        }
        // console.log("🚀 ~ readNotifications ~ payload:", payload)

        const response = await axiosInstance.patch('/notification/list', payload);
        // console.log("🚀 ~ getAllNotifications ~ response:", response)

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            notifications: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}