import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { 
    getAllOrder, 
    getOrder, 
    createOrder, 
    updateOrder, 
    getLatestOrderNo, 
    updateOrderDetail, 
    deleteOrder, 
    kitchenClearOrder, 
    updateOrderReceiptLog, 
    getAllOrderByDateRange,
    updateOrderEmployee,
    updateOrderRemark,
    updateMultipleOrderDetail,
    updateOrderMember
} from '../services/order';
import checkImage from 'utils/checkImage';

export const currentOrder = () => {
    return JSON.parse(window.localStorage.getItem("selectedOrder"));
}

const baseAPI = "https://dev-api.allder.co/";

// State
export const orderState = atom({
    key: 'orderState',
    default: {
        orders: [],
        selectedOrder: {},
        latestOrderNo: null,
        isLoading: false,
        billPageOrders: []
    },
});

// Action
export const useFetchAllOrder = () => {
    const setState = useSetRecoilState(orderState);

    return async (posId, operationDate, forKitchen) => {
        try {
            const fetch = await getAllOrder(posId, operationDate);
            let filter = []
            if(operationDate) {
                // filter = fetch.orders.filter(od => od.operation_date == operationDate);
                filter = fetch.orders;
                if(forKitchen) {
                    // filter = fetch.orders.filter(od => od.status_id == 1);
                    setState(state => ({ ...state, orders: filter.reverse() }));
                } else {
                    setState(state => ({ ...state, orders: filter.reverse() }));
                }
            } else {
            // TODO: Ordering orders from backend
                setState(state => ({ ...state, orders: fetch.orders.reverse() }));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useFetchOrder = () => {
    const setState = useSetRecoilState(orderState);

    return async () => {
        try {
            const fetch = await getOrder();
            setState(state => ({ ...state, orders: fetch.order }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useCreateOrder = () => {
    const setState = useSetRecoilState(orderState);
    const { selectedOrder } = useRecoilValue(orderState);

    // orders is a Order entity vairaible of Home page scope
    return async (orderId, orderNo, orderMenu, orderType, selectedTable, operationDate, customer, employeeId, posId, orderStatusId, orders) => {
        // console.log("🚀 ~ return ~ orders:", orders)
        try {
            const tableActivityId = selectedTable?.activity?.id ? selectedTable.activity.id : selectedTable?.tableActivity?.id  ? selectedTable.tableActivity.id : 1;
            const orderRemark = orders.remark && orders.remark != "" ? orders.remark  : null;
            const create = await createOrder(orderId, orderNo, orderMenu, orderType, tableActivityId, operationDate, customer, employeeId, posId, orderStatusId, orderRemark);
            const orderProducts = [];
            if (create.order?.orderDetail.length > 0) {
                for (const orderDetail of create.order.orderDetail) {
                    const options = [];
                    if (orderDetail.OrderDetailoptionBinding && orderDetail.OrderDetailoptionBinding.length > 0) {
                        for (const option of orderDetail.OrderDetailoptionBinding) {
                            options.push({
                                listID: option.branch_product_option_detail_binding.id,
                                listName: option.branch_product_option_detail_binding.product_option_detail.name,
                                listPrice: option.branch_product_option_detail_binding.price,
                                optionType: option.branch_product_option_detail_binding.product_option_detail.product_option.name,
                                optionTypeId: option.branch_product_option_detail_binding.product_option_detail.product_option.option_type_id,
                                // isMultiple: response.data[i].product_options[j].can_select_multi ? true : false,
                                // optionDetailBindingId: response.data[i].product_options[j].branch_product_option_detail_binding[k].id,
                                optionDetailBindingId: option.branch_product_option_detail_binding_id,
                                optionQty: option.amount
                            })
                        }
                    }
                    
                    var optionPrice = 0;
                    optionPrice = options.reduce((a,b) => a + (b.listPrice * b.optionQty), 0);
                    var productSumPrice = 0;
                    productSumPrice = (orderDetail.branch_product.price + optionPrice) * orderDetail.remain_amount;
                    if(create.status_id == 3 || orderDetail.status_id == 3) {
                        productSumPrice = (orderDetail.branch_product.price + optionPrice) * orderDetail.amount;
                    }

                    orderProducts.push({
                        id: orderDetail.id,
                        productID: orderDetail.branch_product.id,
                        branchProductId: orderDetail.branch_product_id ? orderDetail.branch_product_id : orderDetail.branch_product.id,
                        // productImage: baseAPI + "cdn-cgi/image/fit=scale-down,width=52,quality=40/" + orderDetail.branch_product.product.img_url,
                        productImage: checkImage.checkURL(orderDetail.branch_product.product.img_url),
                        productName: orderDetail.branch_product.product.name,
                        productPrice: orderDetail.branch_product.price,
                        // productSumPrice: orderDetail.sumPrice ?? 0,
                        productSumPrice: productSumPrice,
                        productQty: create.status_id == 3 ? orderDetail.amount : orderDetail.remain_amount,
                        // productStatus: response.data[i].status_id == 4 ? 0 : orderDetail.status_id,
                        // productStatus: create.status_id == 4 ? 0 : 1,
                        productStatus: create.status_id == 4 ? 0 : (create.status_id == 3 || create.status_id == 2) ? create.status_id : create.status_id == 1 ? orderDetail.status_id : 1,
                        optionnote: options.filter(option => option.optionTypeId == 3),
                        optionproduct: options.filter(option => option.optionTypeId == 2),
                        optionstandard: options.filter(option => option.optionTypeId == 1),
                        note: orderDetail.note ?? "",
                        productAmount: orderDetail.amount,
                        productCancel: orderDetail.cancel_amount,
                        productRemain: orderDetail.remain_amount,
                        orderID: orderDetail.order_id,
                        orderNo: orderDetail.main_order_id,
                    })
                }
            }

            const latestOrderNo = await getLatestOrderNo(posId);

            if(create.order.type_id == 1 || orders.delivery == 1) {
                setState(state => ({ ...state, selectedOrder: {}, latestOrderNo: latestOrderNo.orderNo }));
            } else {
                setState(state => ({ ...state, selectedOrder: { ...create.order, product: orderProducts } }));
            }
            
            const fetch = await getAllOrder(posId, operationDate);

            if(orders.billStatus == 4 && create.order.status_id == 1 && (create.order.type_id != 1 || orders.delivery != 1)) {
                // console.log(selectedOrder);
                let newSelectedOrder = {};
                if(selectedOrder.id) {
                    if(selectedOrder.id != create.order.id) {
                        newSelectedOrder = { ...orders, ...create.order };
                    } else {
                        newSelectedOrder = {...selectedOrder, ...create.order};
                    }
                } else {
                    newSelectedOrder = {...orders, ...create.order};
                }
                let newProducts = newSelectedOrder.product?.map(p => ({ ...p, productStatus: 1 }));
                newSelectedOrder.product = newProducts;
                // setState(state => ({ ...state, orders: fetch.orders, selectedOrder: newSelectedOrder }));
                setState(state => ({ ...state, orders: fetch.orders, selectedOrder: newSelectedOrder }));
            }
            else {
                if(create.order.type_id == 1 || orders.delivery == 1) {
                    setState(state => ({ ...state, orders: fetch.orders, selectedOrder: {}, latestOrderNo: latestOrderNo.orderNo }));
                } else {
                    setState(state => ({ ...state, orders: fetch.orders, selectedOrder: { ...state.selectedOrder, ...create.order, orderNo: create.order.order_id, product: orderProducts } }));
                }
            }
            
            return create.order
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useUpdateOrder = () => {
    const setState = useSetRecoilState(orderState);

    return async (order, updateData, tableActivityId, operationDate, posId, employeeId, member) => {
        // console.log("🚀 ~ return ~ member:", member)
        // console.log("🚀 ~ file: order.js:69 ~ return ~ order:", order)
        // console.log("🚀 ~ file: order.js:69 ~ return ~ updateData:", updateData)
        // console.log("🚀 ~ file: order.js:69 ~ return ~ tableActivityId:", tableActivityId)
        // console.log("🚀 ~ file: order.js:69 ~ return ~ operationDate:", operationDate)
        // console.log("🚀 ~ file: order.js:69 ~ return ~ posId:", posId)
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));
            // const update = await updateOrder(order.id, updateData);
            if(order.id) {
                const update = await updateOrder(order.id, updateData);
                // console.log("🚀 ~ return ~ update:", update)
                const fetch = await getAllOrder(update.order.pos_id, operationDate);
                if(update.order.status_id == 4) {
                    setState(state => ({ ...state, orders: fetch.orders, selectedOrder: {} }));
                } else {
                    setState(state => ({ ...state, orders: fetch.orders }));
                }
            } else {
                // Newly create order without send order then hold bill
                const create = await createOrder(order.id, order.orderNo, order.product, order.delivery, tableActivityId, operationDate, member, employeeId, posId, updateData.status_id);
                // console.log("🚀 ~ file: order.js:78 ~ return ~ create:", create.order.id)
                const update = await updateOrder(create.order.id, updateData, member);
                // console.log("🚀 ~ return ~ update:", update)
                const fetch = await getAllOrder(update.order.pos_id, operationDate);
                if(create.order.status_id == 4 || update.order.status_id == 4) {
                    setState(state => ({ ...state, orders: fetch.orders, selectedOrder: {} }));
                } else {
                    setState(state => ({ ...state, orders: fetch.orders }));
                }
            }
            setState(prevState => ({ ...prevState, isLoading: false }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useAddOrder = () => {
    const setState = useSetRecoilState(orderState);

    return async (orderMenu) => {
        // console.log("🚀 ~ file: order.js:59 ~ return ~ orderMenu:", orderMenu)
        try {
            const fetch = await getAllOrder();
            setState(state => ({ ...state, orders: fetch.orders }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useFetchLatestOrderNo = () => {
    const setState = useSetRecoilState(orderState);

    return async (posId) => {
        try {
            const fetch = await getLatestOrderNo(posId);
            setState(state => ({ ...state, latestOrderNo: fetch.orderNo }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useUpdateOrderDetail = () => {
    const setState = useSetRecoilState(orderState);

    return async (orderDetail) => {
        // console.log("🚀 ~ file: order.js:125 ~ return ~ orderDetail:", orderDetail)
        try {
            // Update order detail
            const update = await updateOrderDetail(orderDetail);
            // console.log("🚀 ~ return ~ update:", update)
            // Refresh order
            const fetch = await getAllOrder(update.orderDetail.order.pos_id);
            setState(state => ({ ...state, orders: fetch.orders }));
            return update
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useUpdateMultipleOrderDetail = () => {
    const setState = useSetRecoilState(orderState);

    return async (orderDetailIdList) => {
        // console.log("🚀 ~ file: order.js:125 ~ return ~ orderDetail:", orderDetail)
        try {
            // Update order detail
            const update = await updateMultipleOrderDetail(orderDetailIdList);
            // console.log("🚀 ~ return ~ update:", update)
            // Refresh order
            // const fetch = await getAllOrder(update.orderDetail.order.pos_id);
            // setState(state => ({ ...state, orders: fetch.orders }));
            return update
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useDeleteOrder = () => {
    // const setState = useSetRecoilState(orderState);

    return async (order) => {
        try {
            // Update order detail
            const update = await deleteOrder(order.id);
            // console.log("🚀 ~ return ~ update:", update)
            // Refresh order
            // const fetch = await getAllOrder(order.pos_id);
            // setState(state => ({ ...state, orders: fetch.orders }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useKitchenClearOrder = () => {
    const setState = useSetRecoilState(orderState);

    return async (orderId, orderBatch) => {
        try {
            const update = await kitchenClearOrder(orderId, orderBatch);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};

export const useUpdateOrderReceiptLog = () => {

    return async (receiptId, receiptLogCount, receiptNo) => {
        try {
            // Update order detail
            const update = await updateOrderReceiptLog(receiptId, receiptLogCount, receiptNo);
            // Refresh order
            // const fetch = await getAllOrder(order.pos_id);
            // setState(state => ({ ...state, orders: fetch.orders }));
            return update.order
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useGetOrder = () => {
    // const setState = useSetRecoilState(orderState);

    return async (orderId) => {
        // console.log("🚀 ~ file: order.js:59 ~ return ~ orderMenu:", orderMenu)
        try {
            const fetch = await getOrder(orderId);
            const orderProducts = [];
            if (fetch.order?.orderDetail.length > 0) {
                for (const orderDetail of fetch.order.orderDetail) {
                    const options = [];
                    if (orderDetail.OrderDetailoptionBinding && orderDetail.OrderDetailoptionBinding.length > 0) {
                        for (const option of orderDetail.OrderDetailoptionBinding) {
                            options.push({
                                listID: option.branch_product_option_detail_binding.id,
                                listName: option.branch_product_option_detail_binding.product_option_detail.name,
                                listPrice: option.branch_product_option_detail_binding.price,
                                optionType: option.branch_product_option_detail_binding.product_option_detail.product_option.name,
                                optionTypeId: option.branch_product_option_detail_binding.product_option_detail.product_option.option_type_id,
                                // isMultiple: response.data[i].product_options[j].can_select_multi ? true : false,
                                // optionDetailBindingId: response.data[i].product_options[j].branch_product_option_detail_binding[k].id,
                                optionDetailBindingId: option.branch_product_option_detail_binding_id,
                                optionQty: option.amount
                            })
                        }
                    }
                    
                    var optionPrice = 0;
                    optionPrice = options.reduce((a,b) => a + (b.listPrice * b.optionQty), 0);
                    var productSumPrice = 0;
                    productSumPrice = (orderDetail.branch_product.price + optionPrice) * orderDetail.remain_amount;
                    if(fetch.status_id == 3 || orderDetail.status_id == 3) {
                        productSumPrice = (orderDetail.branch_product.price + optionPrice) * orderDetail.amount;
                    }

                    orderProducts.push({
                        id: orderDetail.id,
                        productID: orderDetail.branch_product.id,
                        branchProductId: orderDetail.branch_product_id ? orderDetail.branch_product_id : orderDetail.branch_product.id,
                        // productImage: "img/product/img1.png",
                        // productImage: baseAPI + "cdn-cgi/image/fit=scale-down,width=52,quality=40/" + orderDetail.branch_product.product.img_url,
                        productImage: checkImage.checkURL(orderDetail.branch_product.product.img_url),
                        productName: orderDetail.branch_product.product.name,
                        productPrice: orderDetail.branch_product.price,
                        // productSumPrice: orderDetail.sumPrice ?? 0,
                        productSumPrice: productSumPrice,
                        productQty: fetch.status_id == 3 ? orderDetail.amount : orderDetail.remain_amount,
                        // productStatus: response.data[i].status_id == 4 ? 0 : orderDetail.status_id,
                        // productStatus: create.status_id == 4 ? 0 : 1,
                        // productStatus: fetch.status_id == 4 ? 0 : (fetch.status_id == 3 || fetch.status_id == 2) ? fetch.status_id : 1,
                        productStatus: orderDetail.status_id ?? 1,
                        optionnote: options.filter(option => option.optionTypeId == 3),
                        optionproduct: options.filter(option => option.optionTypeId == 2),
                        optionstandard: options.filter(option => option.optionTypeId == 1),
                        note: orderDetail.note ?? "",
                        productAmount: orderDetail.amount,
                        productCancel: orderDetail.cancel_amount,
                        productRemain: orderDetail.remain_amount,
                        orderID: orderDetail.order_id,
                        orderNo: orderDetail.main_order_id,
                    })
                }
            }
            return { ...fetch.order, product: orderProducts }
            // setState(state => ({ ...state, orders: fetch.orders }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useFetchAllOrderByDateRange = () => {
    const setState = useSetRecoilState(orderState);

    return async (posId, operationDate, startDate, endDate) => {
        try {
            const fetch = await getAllOrderByDateRange(posId, operationDate, startDate, endDate);
            // let filter = []
            setState(state => ({ ...state, billPageOrders: fetch.orders.reverse() }));
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useUpdateOrderEmployee = () => {
    const setState = useSetRecoilState(orderState);

    return async ({ orderId, employeeId }) => {
        try {
            const update = await updateOrderEmployee({ orderId, employeeId });
            return update.order
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useUpdateOrderRemark = () => {
    const setState = useSetRecoilState(orderState);

    return async ({ id, remark, delivery_remark, delivery_remark_channel }) => {
        // console.log("🚀 ~ return ~ id:", id)
        // console.log("🚀 ~ return ~ remark:", remark)
        try {
            const update = await updateOrderRemark({id, remark, delivery_remark, delivery_remark_channel});
            return update.order
            // return {}
        } catch (error) {
            console.error('Error fetching data:', error);
            // setState(state => ({ ...state, isLoggedIn: false }));
        }
    };
};

export const useUpdateOrderMember = () => {
    const setState = useSetRecoilState(orderState);

    return async ({ orderId, memberId }) => {
        try {
            const update = await updateOrderMember({orderId, memberId});
            return update.order
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
};