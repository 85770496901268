import axiosInstance from './axios';

// const endPoint = "/members";

// const baseAPI = "http://localhost:3000/";
const baseAPI = "https://dev-api.allder.co/";

// TODO: optimized value check on API side
const memberType = {
    "บุคคลธรรมดา": 1,
    "บริษัทจำกัด": 2,
    "บริษัทมหาชนจำกัด": 3,
    "ห้างหุ้นส่วนจำกัด": 4,
    "ร้านค้า": 5,
    "อื่นๆ": 6,
}

const memberTypeList = [
    "บุคคลธรรมดา",
    "บริษัทจำกัด",
    "บริษัทมหาชนจำกัด",
    "ห้างหุ้นส่วนจำกัด",
    "ร้านค้า",
    "อื่นๆ"
]

export const getAllMember = async ({ businessId }) => {
    const members = [];
    try {
        const queryParams = {
            businessId: businessId ?? null
        }
        const response = await axiosInstance.get('/members', { params: queryParams });

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
                const point = response.data[i].MemberPoint?.length > 0 ? response.data[i].MemberPoint.reduce((a,b) => a + b.point ,0) : 0;
                const member = {
                    ...response.data[i],
                    customerType: memberTypeList[response.data[i].type_id - 1],
                    point: point
                }
                members.push(member);
            }
        }

        return {
            // members: response.data
            members: members
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

export const getMember = async (id) => {
    try {
        const response = await axiosInstance.get(`/members/${id}`);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            member: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}

// export const getAllBranchMember = async (branchId) => {
//     const members = [];
//     try {
//         const queryParams = {
//             branch_id: branchId
//         }
//         const response = await axiosInstance.get('/branch-members', { params: queryParams });

//         // TODO:: Add/Update hanlder for HTTP status
//         // if (response.status === 200) {
//         //     console.log('Successful request! Data:', response.data);
//         // } else {
//         //     console.error('Unexpected status code:', response.status);
//         // }

//         if (response.data.length > 0) {
//             for (let i = 0; i < response.data.length; i++) {
//                 let optionStandard = [];
//                 let optionMember = [];
//                 let optionNote = [];
//                 if (response.data[i].member_options.length > 0) {
//                     for (let j = 0; j < response.data[i].member_options.length; j++) {
//                         // if (response.data[i].member_options[j].option_type.id == 1) {
//                         let optionList = []
//                         if (response.data[i].member_options[j].branch_member_option_detial_binding && response.data[i].member_options[j].branch_member_option_detial_binding.length > 0) {
//                             for (let k = 0; k < response.data[i].member_options[j].branch_member_option_detial_binding.length; k++) {
//                                 optionList.push({
//                                     listID: response.data[i].member_options[j].branch_member_option_detial_binding[k].id,
//                                     listName: response.data[i].member_options[j].branch_member_option_detial_binding[k].member_option_detail.name,
//                                     listPrice: response.data[i].member_options[j].branch_member_option_detial_binding[k].member_option_detail.price,
//                                     optionType: response.data[i].member_options[j].name,
//                                     isMultiple: response.data[i].member_options[j].can_select_multi ? true : false,
//                                     optionDetailBindingId: response.data[i].member_options[j].branch_member_option_detial_binding[k].id
//                                 })
//                             }
//                         }
//                         if (response.data[i].member_options[j].option_type.id == 1) {
//                             optionStandard.push({
//                                 optionID: response.data[i].member_options[j].id,
//                                 optionName: response.data[i].member_options[j].name,
//                                 selecttype: response.data[i].member_options[j].can_select_multi ? 1 : 0,
//                                 optionList: optionList
//                             })
//                         }
//                         if (response.data[i].member_options[j].option_type.id == 2) {
//                             optionMember.push({
//                                 optionID: response.data[i].member_options[j].id,
//                                 optionName: response.data[i].member_options[j].name,
//                                 selecttype: response.data[i].member_options[j].can_select_multi ? 1 : 0,
//                                 optionList: optionList
//                             })
//                         }
//                         if (response.data[i].member_options[j].option_type.id == 3) {
//                             optionNote = optionList;
//                         }
//                     }
//                 }
//                 const member = {
//                     memberID: response.data[i].id,
//                     // memberImage: "img/member/img2.png",
//                     memberImage: baseAPI + response.data[i].member.img_url,
//                     memberName: response.data[i].member.name,
//                     memberPrice: response.data[i].price,
//                     optionstandard: optionStandard,
//                     optionmember: optionMember,
//                     optionnote: optionNote,
//                     branchMemberId: response.data[i].id,
//                     branchId: response.data[i].branch.id,
//                     // branchMember: response.data[i].branch_member ? response.data[i].branch_member : null,
//                     // subCategoryId: response.data[i].sub_member_category_id ? response.data[i].sub_member_category_id : null
//                     subCategoryId: response.data[i].member.sub_member_category_id
//                 }
//                 members.push(member);
//             }
//         }

//         return {
//             members: members
//         }
//     } catch (error) {
//         console.error('Error fetching data:', error);
//         return error
//     }
// }

export const createMember = async (memberDetail) => {
    try {
        var memberBirthDate = null
        var age = 0;
        if(memberDetail.birthdate && memberDetail.birthdate != "") {
            memberBirthDate = new Date(memberDetail.birthdate);
            const currentDate = new Date();
            age = currentDate.getFullYear() - memberBirthDate.getFullYear();
        }
        const gender = memberDetail.gender == "ชาย" ? "Male" : memberDetail.gender == "หญิง" ? "Female" : "NotSpecified";
        const payload = {
            type_id: memberType[memberDetail.customer_type] ?? 1,
            code: memberDetail.code,
            img_url: "",
            name: `${memberDetail.first_name} ${memberDetail.last_name}` ?? "",
            firstname: memberDetail.first_name ?? "",
            lastname: memberDetail.last_name ?? "",
            id_number: memberDetail.tax_id ?? "",
            gender: gender,
            birthdate: memberBirthDate ? memberBirthDate.toISOString() : null,
            age: age ?? 0,
            phone_number: memberDetail.phone_number ?? "",
            email: memberDetail.email ?? "",
            customer_group: "",
            member_level: "",
            business_id: memberDetail.businessId ?? null,
            branch_id: memberDetail.branchId ?? null,
        }
        // console.log("🚀 ~ file: member.js:174 ~ createMember ~ payload:", payload)

        const response = await axiosInstance.post(`/members/`, payload);

        // TODO:: Add/Update hanlder for HTTP status
        // if (response.status === 200) {
        //     console.log('Successful request! Data:', response.data);
        // } else {
        //     console.error('Unexpected status code:', response.status);
        // }

        return {
            member: response.data
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return error
    }
}